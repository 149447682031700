import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "default-page-container" }
const _hoisted_2 = { class: "flex-column default-page-padding-inline" }
const _hoisted_3 = { class: "table-scroll-container" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableActionsComponent = _resolveComponent("TableActionsComponent")!
  const _component_PanelFilters = _resolveComponent("PanelFilters")!
  const _component_TableMultiHeaders = _resolveComponent("TableMultiHeaders")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    "body-style": { height: '100%', padding: 0 },
    "content-style": { height: '100%' },
    style: { width: '95vw', height: '95vh' },
    visible: true,
    title: "Экспорт",
    onClose: _ctx.close
  }, {
    body: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.table?.tableDataSet.isTableMounted)
            ? (_openBlock(), _createBlock(_Teleport, {
                key: 0,
                disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
                to: '#' + _ctx.table.tableDataSet.idContainerDivUp
              }, [
                (_ctx.table)
                  ? (_openBlock(), _createBlock(_component_TableActionsComponent, {
                      key: 0,
                      "table-data-set": _ctx.table.tableDataSet,
                      "changes-state": "hide",
                      "filter-state": "enable",
                      "row-append-state": "hide",
                      style: {"padding-bottom":"10px"}
                    }, null, 8, ["table-data-set"]))
                  : _createCommentVNode("", true),
                (
                _ctx.table.tableDataSet && _ctx.table.tableDataSet.options.filtersSet.open
              )
                  ? (_openBlock(), _createBlock(_component_PanelFilters, {
                      key: 1,
                      "data-set": _ctx.table.tableDataSet.options.filtersSet,
                      "model-array": _ctx.table.tableDataSet.model,
                      "table-data-set": _ctx.table.tableDataSet,
                      types: _ctx.table.tableDataSet.types,
                      style: {"padding-bottom":"10px"}
                    }, null, 8, ["data-set", "model-array", "table-data-set", "types"]))
                  : _createCommentVNode("", true)
              ], 8, ["disabled", "to"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.table)
            ? (_openBlock(), _createBlock(_component_TableComponent, {
                key: 0,
                isShowInfo: false,
                "table-data-set": _ctx.table.tableDataSet
              }, {
                headers: _withCtx(({ classes, tableDataSet }) => [
                  _createVNode(_component_TableMultiHeaders, {
                    classes: classes,
                    headers: _ctx.table.factory.options.headers,
                    model: _ctx.table.factory.modelDtoSort,
                    "table-data-set": tableDataSet,
                    onClickLeftCol: ($event: any) => (tableDataSet.selectedAll(this.$nextTick))
                  }, null, 8, ["classes", "headers", "model", "table-data-set", "onClickLeftCol"])
                ]),
                link: _withCtx(({
                  tableDataSet,
                  model,
                  colIndex,
                  rowIndex,
                  data,
                  original,
                  tableRow,
                  classes,
                  type,
                }) => [
                  _createElementVNode("span", null, [
                    (tableRow.original.link)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: `${tableRow.original.link}`,
                          target: "_blank"
                        }, " Скачать ", 8, _hoisted_4))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["table-data-set"]))
            : (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_ScreenSpinner, {
                  key: 1,
                  dimming: false
                }))
              : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}