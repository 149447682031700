
import { defineComponent, PropType } from "vue";
import CreateReportFormModal from "@/modules/registration-reports/components/CreateReportFormModal/CreateReportFormModal.vue";
import ApiServiceRegReports from "@/modules/registration-reports/services/ApiServiceRegReports/ApiServiceRegReports";
import { ReportDto } from "@/modules/registration-reports/services/ApiServiceRegReports/types";
import LoadReportForm from "@/modules/registration-reports/components/LoadReportForm.vue";
import ReportFormContextMenu from "@/modules/editor-forms/components/ReportFormContextMenu/ReportFormContextMenu.vue";
import { FormsExtraInfo } from "@/services/AppApiFormsService/types/FormsExtraInfo";
import AppApiFormsService from "@/services/AppApiFormsService/AppApiFormsService";
import AppApiRepositoryService from "@/services/AppApiRepositoryService/AppApiRepositoryService";
import {
  JournalRepositoryDto,
  JournalValueDto,
} from "@/services/AppApiRepositoryService/types/JournalRepositoryDto";
import { JournalRequiredFieldsDto } from "@/services/AppApiRepositoryService/types/JournalRequiredFieldsDto";
import ApiActionsService from "@/services/ApiActions/ApiActionsService";
import {
  ActionReportForms,
  ActionsExecJournalContext,
} from "@/services/ApiActions/types";
import { JournalDataSet } from "@/modules/registration-reports/classes/JournalDataSet";
import {
  ApiHelper,
  BlueHelpComponent,
  ButtonComponent,
  CircleLoader,
  Dictionary,
  EnterModalComponent,
  EnterModalProps,
  FormInfoDataDto,
  FormReadDto,
  getStatusReportName,
  isNullOrUndefined,
  Logger,
  NotificationsService,
  PanelFilters,
  PanelFiltersSet,
  ScreenSpinner,
  TABLE_CLASSES,
  TABLE_ICONS,
  TableActionsComponent,
  TableClasses,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableMultiHeaders,
  TablePagination,
  TablePaginationBottom,
  VOC_NAMES_DICT,
} from "table";
import { TableCellPointer } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import { TableRowObj } from "table/dist/types/TableRowObj";
import { PaginationMode } from "table/dist/types/LazyPaginationState";
import { ApiListWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { ApiSelectValue } from "table/dist/services/Api/types/ApiSelectValue";
import ExportWirthTitlePagesModal, {
  ExportWirthTitlePagesProps,
} from "@/modules/editor-forms/components/ExportWirthTitlePagesModal/ExportWirthTitlePagesModal.vue";
import { useSaveSettingsInTableDataSet } from "@/services/UserSettingsService/helpers";
import { PanelFiltersSave } from "table/dist/components/PanelFilters/types";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";

interface JournalData {
  tableDataSet: TableDataSet;
  factory: TableDataSetFactory;
  open: {
    info: boolean;
  };
}

export default defineComponent({
  name: "ReportPanelListV3",
  components: {
    ActionsButtons,
    ExportWirthTitlePagesModal,
    CreateReportFormModal,
    ButtonComponent,
    LoadReportForm,
    ReportFormContextMenu,
    TableComponent,
    CircleLoader,
    TableMultiHeaders,
    BlueHelpComponent,
    EnterModalComponent,
    TablePaginationBottom,
    ScreenSpinner,
    TableActionsComponent,
    PanelFilters,
  },
  props: {
    tableClasses: {
      type: Object as PropType<TableClasses>,
      default: TABLE_CLASSES,
    },
    preset: String,
  },
  setup() {
    return {
      TABLE_ICONS,
      getStatusReportName,
      VOC_NAMES_DICT,
    };
  },
  data() {
    return {
      open: {
        export: null as null | ExportWirthTitlePagesProps,
        createReportForm: false,
        loadReportForm: false,
        enterModal: null as null | EnterModalProps,
      },
      loading: {
        unload: false,
      },
      table: null as JournalData | null,
      heightPx: {
        regReportsWithoutTableContainer: 300,
        appHeader: 50,
        componentPadding: 36,
      },
      formsExtraInfo: null as FormsExtraInfo | null,
      journalRepository: null as JournalRepositoryDto | null,
      journalList: null as JournalRequiredFieldsDto[] | null,
      formsJournals: null as null | ApiListWrapper<JournalValueDto>,
      actionsReportForms: null as ActionReportForms[] | null,
      journalDataSet: null as JournalDataSet | null,
      presets: undefined as undefined | PanelFiltersSave[],
      isCreated: false,
    };
  },
  async created() {
    window.addEventListener("resize", this.resizeHandler);
    await ApiHelper.wrapNotifyError(async () => {
      const formJournals$ = AppApiRepositoryService.getFormsJournals();
      const formsExtraInfo$ = AppApiFormsService.extraInfo();
      const actionsReportForms$ = ApiActionsService.getListActionsReportForms();

      this.formsJournals = (await formJournals$).json;
      this.formsExtraInfo = (await formsExtraInfo$).json;
      this.actionsReportForms = (await actionsReportForms$).json;
      await this.updateJournal(true);
    });

    this.$watch(
      () => this.preset,
      async () => {
        await this.updateJournal(true);
      },
    );
    this.$watch(
      () => this.routeParams.journal_name,
      async () => {
        if (!this.routeParams.journal_name) {
          return;
        }

        await this.updateJournal(true);
      },
    );
    this.isCreated = true;
  },
  updated() {
    this.setHeights();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  computed: {
    presetsId(): string | undefined {
      if (!this.journalDataSet) {
        return undefined;
      }

      return (
        "report-panel-list-v3" +
        "_" +
        this.journalDataSet.journal_name +
        "_" +
        this.journalDataSet.preset
      );
    },
    formJournalCaption(): string {
      if (!this.formJournal) {
        return "Загрузка..";
      }

      let caption = this.formJournal.label;
      if (this.formJournalPreset) {
        caption += ` - ${this.formJournalPreset.label}`;
      }

      return caption;
    },
    formJournal(): JournalValueDto | undefined {
      return this.formsJournals?.result.find(
        (x) => x.value === this.routeParams.journal_name,
      );
    },
    formJournalPreset(): ApiSelectValue | undefined {
      if (isNullOrUndefined(this.preset)) {
        return undefined;
      }

      return this.formJournal?.presets.find((x) => x.value === this.preset);
    },

    routeParams(): { journal_name: string } {
      return this.$route.params as any;
    },

    isDisabledUnload(): boolean {
      return (
        !this.formsExtraInfo ||
        !this.formsExtraInfo.right_to_act.unload_exchange_file ||
        Object.keys(this.selectedForms).length === 0 ||
        this.loading.unload
      );
    },

    isDisabledUpload(): boolean {
      return (
        !this.formsExtraInfo ||
        !this.formsExtraInfo.right_to_act.upload_exchange_file ||
        Object.keys(this.selectedForms).length === 0 ||
        this.loading.unload
      );
    },

    isDisabledExport(): boolean {
      return Object.keys(this.selectedForms).length === 0;
    },

    tableContainerStyle(): Object {
      const addedHeight = Object.values(this.heightPx).reduce(
        (sum, current) => sum + current,
        0,
      );
      const height = `calc(100vh - ${addedHeight}px)`;
      return {
        // height
      };
    },

    selectedValues(): TableRowObj[] {
      if (!this.table) {
        return [];
      }
      return this.table.tableDataSet.rows.filter(
        (x) => x.select,
      ) as TableRowObj[];
    },

    selectedForms(): Dictionary<JournalRequiredFieldsDto> {
      return this.selectedValues.reduce((selectedForms, tableRow) => {
        const form: JournalRequiredFieldsDto = tableRow.original;
        selectedForms[form.report_form_id] = form;
        return selectedForms;
      }, {} as Dictionary<JournalRequiredFieldsDto>);
    },
  },
  methods: {
    initJournalDataSet(journalRepository: JournalRepositoryDto) {
      this.journalDataSet = new JournalDataSet({
        journal_name: this.routeParams.journal_name,
        preset: this.preset,
        uniqueId: `${this.routeParams.journal_name}_${this.preset}`,
        lazy_load_size: journalRepository.lazy_load_size,
      });
    },
    resizeHandler() {
      this.setHeights();
    },

    setHeights() {
      const regReportsWithoutTableContainer = (
        this.$refs.regReportsWithoutTableContainer as HTMLDivElement
      )?.clientHeight;
      if (
        !isNullOrUndefined(regReportsWithoutTableContainer) &&
        regReportsWithoutTableContainer !==
          this.heightPx.regReportsWithoutTableContainer
      ) {
        this.heightPx.regReportsWithoutTableContainer =
          regReportsWithoutTableContainer;
      }
    },

    destroyTable() {
      if (this.table) {
        this.table.tableDataSet.destroy();
      }

      this.table = null;
    },

    async initTable() {
      if (
        !this.journalRepository ||
        !this.journalList ||
        !this.journalDataSet
      ) {
        throw new Error("not found data");
      }

      this.destroyTable();
      const filtersSaveModule = new PanelFiltersSaveModule(this.presetsId!);
      await filtersSaveModule.init();
      const filtersSet = new PanelFiltersSet(filtersSaveModule);
      filtersSet.subject.subscribe((data) =>
        applyPanelFiltersData(this.table!.tableDataSet as TableDataSet, data),
      );
      const factory = new TableDataSetFactory({
        tableName: "journal",
        uniqId: this.routeParams.journal_name,
        headers: this.journalRepository.table.headers,
        types: this.journalRepository.types,
        rows: this.journalList.map((journal) => {
          return journal as Dictionary;
        }),
        edit: {},
        modelDtoArray: this.journalRepository.table.model,
        tableDataSetOptions: {
          pagination: this.journalDataSet.pagination as TablePagination,
          searchDebounce: 300,
          display: {
            incCol: 1,
            incRow: 0,
            rowIndexCol: false,
          },
          listeners: {
            destroy: {
              next: (tableDataSet) => {
                this.journalDataSet?.tableDataSetDestroy(tableDataSet);
              },
            },
            changeFilters: {
              next: async () => {
                if (!this.journalDataSet || !this.table) {
                  return;
                }

                await this.journalDataSet.onChangeFilters(
                  this.table.tableDataSet as TableDataSet,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
            changeSort: {
              next: async () => {
                if (!this.journalDataSet || !this.table) {
                  return;
                }

                await this.journalDataSet.onChangeSort(
                  this.table.tableDataSet as TableDataSet,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
          },
          filtersSet,
        },
      });
      const tableDataSet = await factory.create();
      this.journalDataSet.setInfoData(tableDataSet, this.journalList);
      await useSaveSettingsInTableDataSet(tableDataSet);
      this.table = {
        tableDataSet,
        factory,
        open: {
          info: true,
        },
      };
      await this.journalDataSet.updateValuesRemain(
        this.table.tableDataSet as TableDataSet,
      );
    },

    async updateJournal(updateRepository: boolean) {
      this.destroyTable();
      await ApiHelper.wrapNotifyError(async () => {
        if (updateRepository || !this.journalRepository) {
          this.journalRepository = null;
          const { json: journalRepository } =
            await AppApiRepositoryService.getJournalRepository(
              this.routeParams.journal_name,
            );

          this.journalRepository = journalRepository;
        }

        if (updateRepository) {
          this.initJournalDataSet(this.journalRepository!);
        }

        if (!this.journalDataSet) {
          throw new Error("not found journalDataSet");
        }

        const dataSetData = await this.journalDataSet!.get();
        if (dataSetData) {
          this.journalList = dataSetData.values;
          await this.initTable();
        }
      });
    },

    updateFormRead(formRead: FormReadDto) {
      if (!this.journalList) {
        return;
      }

      const row = this.journalList.findIndex(
        (x) => x.report_form_id === formRead.report_form_id,
      );
      if (row === -1) {
        return;
      }

      const formReadCurrent = this.journalList![row];
      Object.assign(formReadCurrent, formRead);
      if (!this.table) {
        return;
      }

      const pointer = {
        row,
        col_name: "form_name",
      };

      const formInfoData: FormInfoDataDto | undefined = formRead.form_comment
        ?.text
        ? {
            pointer,
            value: formRead.form_comment as FormInfoDataDto["value"],
          }
        : undefined;

      this.table.tableDataSet.info.setDataByPointer(
        "comment",
        pointer,
        formInfoData,
      );
    },

    async onDeleteReport(report: ReportDto) {
      try {
        const result = (
          await ApiServiceRegReports.deleteReport(report.report_id)
        ).json;
        if (!result) {
          await this.updateJournal(false);
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при удалении отчёта",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error(e);
      }
    },

    async onSuccessDeleteForm({ form }: { form: JournalRequiredFieldsDto }) {
      if (!this.journalList) {
        return;
      }

      this.journalList =
        this.journalList.filter(
          (x) => x.report_form_id !== form.report_form_id,
        ) || null;
      await this.initTable();
    },

    async onAddReport(report: ReportDto) {
      await this.updateJournal(false);
    },

    onExport() {
      this.open.export = { form_id: Object.keys(this.selectedForms) };
      this.onSelectedAll("clear");
    },

    onUnloadCpFile() {
      ApiServiceRegReports.formUnloadCpFile(Object.keys(this.selectedForms));
    },

    async onCreateAdjustmentReport() {
      this.loading.unload = true;
      try {
        await ApiServiceRegReports.formsCreateAdjustment(
          Object.keys(this.selectedForms).map(Number),
        );
        await this.updateJournal(false);
      } catch (e) {
        await ApiHelper.wrapNotifyError(e, {
          isError: true,
          title:
            "Произошла ошибка при создании корректировки для отчётной формы",
        });
      }
      this.loading.unload = false;
    },

    async onActionsReportFormsExec(action: ActionReportForms) {
      if (!this.table?.tableDataSet) {
        return;
      }

      try {
        const tableDataSet = this.table.tableDataSet;
        const selected_row = tableDataSet.getRow(
          tableDataSet.selected.cursor?.row,
        )?.original;
        const selected_rows = this.selectedValues.map((x) => x.original);
        const context: ActionsExecJournalContext = {
          selected_rows,
          selected_row,
        };

        const result = await ApiActionsService.executeExec(
          action,
          context,
          true,
        );
        // временно по умолчанию перезагружаем всё
        const refresh = result.result?.refresh ?? "all";
        if (refresh) {
          await this.updateJournal(refresh === "all");
        }
      } catch (ex) {
        Logger.error(ex);
        await ApiHelper.wrapNotifyError(ex, { isError: true });
      }
    },

    setHelp(table: JournalData, pointer: Required<TableCellPointer> | null) {
      if (!pointer) {
        return;
      }

      const model = table.tableDataSet.model[pointer.col];
      table.tableDataSet.helpHelper.setHelp(model.modelDto.help || null, {
        tableDataSet: table.tableDataSet,
        field: pointer.col_name,
      });
    },

    onSwitchRow(row: number) {
      const tableRow = this.table!.tableDataSet.getRow(row);
      if (!tableRow) {
        return;
      }

      tableRow.select = !tableRow.select;
    },

    onSelectedAll(mode: "" | "clear" | "all" = "") {
      if (!this.table) {
        return;
      }

      const isSelectedAll =
        this.selectedValues.length === this.table.tableDataSet.rows.length;
      if (mode === "clear" || isSelectedAll) {
        this.table.tableDataSet.rows.forEach((x) => (x.select = false));
      } else {
        this.table.tableDataSet.rows.forEach((tableRow) => {
          tableRow.select = true;
        });
      }
    },

    openContextMenuWithReportForm(event: MouseEvent, form: FormReadDto) {
      this.getReportFormContextMenuComponent().open(event, form);
    },

    getReportFormContextMenuComponent() {
      return this.$refs.reportFormContextMenu as InstanceType<
        typeof ReportFormContextMenu
      >;
    },

    async onPagination(mode: PaginationMode) {
      if (!this.journalDataSet || !this.table) {
        return;
      }

      await this.journalDataSet.paginationLoad(
        mode,
        this.table.tableDataSet as TableDataSet,
        this.table.factory as TableDataSetFactory,
      );
    },
  },
});
