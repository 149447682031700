import {
  ApiHelper,
  ApiService,
  FilterFactory,
  FormInfoDataDto,
  isNumeric,
  Logger,
  PaginationHelper,
  SortFactory,
  TableDataSet,
  TableDataSetFactory,
  TablePagination,
} from "table";
import { ApiPaginationParams } from "table/dist/services/Api/types/ApiPaginationParams";
import { JournalDataSetData } from "@/modules/registration-reports/common/types";
import { PaginationMode } from "table/dist/types/LazyPaginationState";
import { ApiListLazyWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { ApiSortField } from "table/dist/services/Api/types/ApiSortField";
import { FilterLogicalObj } from "table/dist/services/Api/types/ApiFilter";
import AppApiFormsService from "@/services/AppApiFormsService/AppApiFormsService";
import { JournalRequiredFieldsDto } from "@/services/AppApiRepositoryService/types/JournalRequiredFieldsDto";

interface JournalDataSetParams {
  lazy_load_size?: number;
  uniqueId?: string;
  journal_name: string;
  preset?: string;
}

export class JournalDataSet {
  journal_name: string;
  preset?: string;
  lazy_load_size?: number;
  uniqueId = Math.random().toString();
  pagination!: TablePagination;
  data: JournalDataSetData | undefined = undefined;

  constructor(params: JournalDataSetParams) {
    this.journal_name = params.journal_name;
    this.preset = params.preset;
    this.lazy_load_size = params.lazy_load_size;
    if (params.uniqueId) {
      this.uniqueId = params.uniqueId;
    }

    this.initPagination();
  }

  static async getData(
    journal_name: string,
    preset?: string,
    paginationParams?: ApiPaginationParams,
    editingVocDataSetData?: JournalDataSetData,
  ): Promise<JournalDataSetData> {
    const { json: valuesResult } = await AppApiFormsService.getListForJournal(
      journal_name,
      preset,
      paginationParams,
    );

    let values = valuesResult.result;
    if (editingVocDataSetData) {
      if (paginationParams?.offset) {
        values = [...editingVocDataSetData.values, ...values];
      }
    }

    const lazyPaginationState = paginationParams
      ? PaginationHelper.getStateByLazyResult(
          valuesResult as ApiListLazyWrapper<any>,
        )
      : undefined;

    return {
      values,
      newValues: valuesResult.result,
      lazyPaginationState,
    };
  }

  async initRows(
    dataSetData: JournalDataSetData,
    tableDataSet: TableDataSet,
    factory: TableDataSetFactory,
  ) {
    const tableRows = factory
      .setRows(dataSetData.values, dataSetData.values)
      .getTableRows(tableDataSet.model);
    tableDataSet.initRows(tableRows);
    tableDataSet.clearInfoData();
    this.setInfoData(tableDataSet, dataSetData.values);
    await this.updateValuesRemain(tableDataSet);
  }

  clearCache(): this {
    this.initPagination();
    ApiService.clearCache(this.getKey());
    this.data = undefined;
    return this;
  }

  // если ключ поменялся - очищаем кэш для предыдущего ключа
  checkCache(prevKey: string, newKey: string) {
    if (prevKey === newKey) {
      return;
    }

    ApiService.clearCache(prevKey);
  }

  async get(
    paginationParams = this.pagination.params,
  ): Promise<JournalDataSetData | undefined> {
    this.pagination.setLoading(true);
    try {
      const data = await ApiService.useCache(
        this.getKey(paginationParams),
        () => this.getEditingVocDataSetData(paginationParams),
      );
      this.setPaginationParams(paginationParams);
      this.pagination.setLoading(false);
      this.data = data;
      return data;
    } catch (e: any) {
      await ApiHelper.wrapNotifyError(e, {
        isError: true,
        title: "Произошла ошибка при загрузке данных таблицы",
      });
      Logger.error({ e });
      this.pagination.setLoading(false);
      return undefined;
    }
  }

  async paginationLoad(
    mode: PaginationMode,
    tableDataSet: TableDataSet,
    factory: TableDataSetFactory,
  ) {
    const paginationParams = PaginationHelper.getPaginationByMode(
      mode,
      this.pagination,
    );
    if (!paginationParams) {
      return;
    }

    const dataSetData = await this.get(paginationParams);
    if (dataSetData) {
      await this.initRows(dataSetData, tableDataSet, factory);
    }
  }

  setPaginationParams(pagination: ApiPaginationParams) {
    const prevKey = this.getKey(this.pagination.params);
    this.pagination.setParams(pagination);
    const newKey = this.getKey(pagination);
    this.checkCache(prevKey, newKey);
  }

  setFilter(filter: FilterLogicalObj | undefined) {
    this.setPaginationParamsPartial({
      filter: FilterFactory.getString(filter),
    });
  }

  setSort(sort: ApiSortField[] | undefined) {
    this.setPaginationParamsPartial({ sort: SortFactory.getString(sort) });
  }

  setPaginationParamsPartial(paginationParams: Partial<ApiPaginationParams>) {
    this.setPaginationParams({
      ...this.pagination.params,
      ...paginationParams,
    });
  }

  async onChangeFilters(
    tableDataSet: TableDataSet,
    factory: TableDataSetFactory,
  ) {
    if (!tableDataSet.isServerManagement) {
      await this.setFilter(undefined);
      return;
    }

    this.pagination.setOffset(0).normaliseLimit();
    const filter = tableDataSet.getFilterLogicalObj();
    this.setFilter(filter);
    await this.onLoad(tableDataSet, factory);
  }

  async onChangeSort(tableDataSet: TableDataSet, factory: TableDataSetFactory) {
    if (!tableDataSet.isServerManagement) {
      await this.setSort(undefined);
      return;
    }

    this.pagination.setOffset(0).normaliseLimit();
    const sort = tableDataSet.getSort();
    this.setSort(sort);
    await this.onLoad(tableDataSet, factory);
  }

  async updateValuesRemain(tableDataSet: TableDataSet) {
    if (!this.data?.lazyPaginationState) {
      return;
    }

    const pagination = this.pagination;
    pagination.setValuesRemain(this.data.lazyPaginationState.values_remain);
    tableDataSet.options.pagination = pagination;
  }

  async onLoad(tableDataSet: TableDataSet, factory: TableDataSetFactory) {
    const dataSetData = await this.get();
    if (dataSetData) {
      await this.initRows(dataSetData, tableDataSet, factory);
    }
  }

  destroy() {
    this.clearCache();
  }

  tableDataSetDestroy(tableDataSet: TableDataSet) {
    this.pagination.clear();
    this.initPagination();
  }

  setInfoData(
    tableDataSet: TableDataSet,
    journalList: JournalRequiredFieldsDto[],
  ) {
    const infoData = {
      comment: journalList
        .map((x, row) => {
          if (!x.form_comment?.text) {
            return undefined;
          }

          return {
            pointer: {
              row,
              col_name: "form_name",
            },
            value: x.form_comment,
          } as FormInfoDataDto;
        })
        .filter(Boolean) as FormInfoDataDto[],
    };
    tableDataSet.setInfoData(infoData);
  }

  private getKey(pagination = this.pagination.params) {
    return `journal-data-set-${this.journal_name}_${
      this.uniqueId
    }_${TablePagination.getKey(pagination)}`;
  }

  private initPagination() {
    if (!this.pagination) {
      const lazyLoadSize = isNumeric(this.lazy_load_size)
        ? this.lazy_load_size
        : undefined;
      this.pagination = new TablePagination({
        lazyLoadSize,
      });
    }

    this.pagination.setOffset(0);
  }

  private getEditingVocDataSetData(
    paginationParams = this.pagination.params,
  ): Promise<JournalDataSetData> {
    return JournalDataSet.getData(
      this.journal_name,
      this.preset,
      paginationParams,
      this.data,
    );
  }
}
